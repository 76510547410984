var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"input-group bordered"},[_c('div',{staticClass:"car-number"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.plateNum),expression:"plateNum"},{name:"mask",rawName:"v-mask",value:(_vm.plateNumMask),expression:"plateNumMask"},{name:"tooltip",rawName:"v-tooltip.top",value:({
        html:true,
        theme:'info-text',
        distance:8,
        content:`
          Допустимые символы для поля:<br>
          <ul>
            <li>цифры - <span>0-9</span>;</li>
            <li>буквы - <span>А, В, Е, К, М, Н, О, Р, С, Т, У, Х</span></li>
          </ul>
        `,
        triggers: [],
        shown: _vm.isTooltipShown
      }),expression:"{\n        html:true,\n        theme:'info-text',\n        distance:8,\n        content:`\n          Допустимые символы для поля:<br>\n          <ul>\n            <li>цифры - <span>0-9</span>;</li>\n            <li>буквы - <span>А, В, Е, К, М, Н, О, Р, С, Т, У, Х</span></li>\n          </ul>\n        `,\n        triggers: [],\n        shown: isTooltipShown\n      }",modifiers:{"top":true}}],staticClass:"form-control",attrs:{"id":_vm.numId,"type":"text","placeholder":"А 000 АА"},domProps:{"value":(_vm.plateNum)},on:{"input":[function($event){if($event.target.composing)return;_vm.plateNum=$event.target.value},function($event){_vm.$emit('input', $event.target.value.replace(/\s+/g, '') + _vm.plateReg)}],"keypress":(e) => {!e.key.match(_vm.plateNumPatternWithNums) ? _vm.isTooltipShown = true : _vm.isTooltipShown = false},"blur":function($event){_vm.isTooltipShown = false}}})]),_vm._v(" "),_c('div',{staticClass:"divider"}),_vm._v(" "),_c('div',{staticClass:"region"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.plateReg),expression:"plateReg"},{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],ref:'regInput_' + _vm.id,staticClass:"form-control region",attrs:{"id":_vm.regId,"type":"text","placeholder":"000"},domProps:{"value":(_vm.plateReg)},on:{"input":[function($event){if($event.target.composing)return;_vm.plateReg=$event.target.value},function($event){_vm.$emit('input', _vm.plateNum.replace(/\s+/g, '') + $event.target.value)}]}}),_vm._v(" "),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flag"},[_c('span',[_vm._v("RUS")]),_vm._v(" "),_c('img',{attrs:{"src":require("assets/img/ru.svg"),"alt":"флаг России"}})])
}]

export { render, staticRenderFns }