
import { Component, Vue } from 'nuxt-property-decorator'
import DocWidgetList from '@pp-frontend/shared-packages/components/Doc/WidgetList/DocWidgetList.vue'
import carTaxCard from '@pp-frontend/car-tax-widget/components/carTaxCard.vue'
import OfficialSource from '~/components/index/OfficialSource.vue'
import FeaturesIndex from '~/components/index/FeaturesIndex.vue'
import SearchForm from '~/components/search-form/SearchForm.vue'
import SearchFormTemplateUI from '~/components/UI/SearchFormTemplateUI.vue'

@Component({
  components: {
  SearchFormTemplateUI,
  SearchForm,
  FeaturesIndex,
  OfficialSource,
  DocWidgetList,
  carTaxCard
  }
  })
export default class extends Vue {
  allowServices: any = this.$allowServices
}
