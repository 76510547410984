
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import carTaxCard from '@pp-frontend/car-tax-widget/components/carTaxCard.vue'

@Component({
  components: {
  carTaxCard
  }
  })
export default class SearchFormTemplateUI extends Vue {
  @Prop({ default: 'margin-bottom: 3rem;' }) searchFormCardStyle!: string
  @Prop({ default: 'h1' }) titleTag: string

  allowServices: any = this.$allowServices()
}
