<template functional>
  <div class="col-12 col-lg-5">
    <div class="fssp-block">
      <div class="d-flex align-items-center pl-4 pr-4">
        <img
          src="~/assets/img/fssp-block.svg"
          width="236"
          height="189"
          alt="calendar"
          class="calendar-img"
        >
        <h4>
          Если оплата<br>
          по штрафу
          уже просрочена
        </h4>
      </div>
      <p class="fssp-desc">
        Проверьте задолженность перед судебными приставами, чтобы не получить исполнительский сбор - не менее 1000 рублей.
      </p>
      <div class="div d-flex justify-content-center">
        <a href="https://oplata-fssp.ru/" target="_blank" rel="noopener noreferrer" class="go-to-fssp">Проверить задолженность ФССП</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  .fssp-block{
    background: rgba(224, 80, 86, 0.05);
    border-radius: 12px;
    padding: 30px 0;

    @include bp-max($mq-mobile-medium) {
      padding-top: 5px;
      margin-bottom: 42px;
    }
  }
  h4{
    margin-left: 16px;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
    font-family: var(--font-base);
    font-weight: 600;
    @media (max-width:991px) {
      color: #E05056;
    }
  }
  .calendar-img{
    @media (max-width:991px) {
      max-width: 35%;
    }
  }
  .fssp-desc{
    margin: 0 27px;
    font-size: 16px;
    line-height: 21px;
    color: #3E3E3E;
  }
  .go-to-fssp{
    margin: 55px 27px 0;
    background: #E05056;
    border-radius: 8px;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 150.18%;
    font-family: var(--font-base);
    font-weight: 600;
    padding: 12px 20px;
    text-align: center;
    width: 100%;
    &:hover{
      text-decoration: none;
      color: #FFFFFF;
    }
  }
</style>
