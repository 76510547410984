
import { Component, Vue } from 'nuxt-property-decorator'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
@Component({
  components: {
    Swiper,
    SwiperSlide
  }
})
export default class extends Vue {
  name: 'Services'
  swiperOption = {
    spaceBetween: 16,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet (index, className) {
        return `<span class="${className} swiper-pagination-bullet-custom">${index + 1}</span>`
      }
    }
  }
}
