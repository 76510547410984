
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  google: string = 'https://play.google.com/store/apps/details?id=com.simplepayments_gibdd'
  apple: string = 'https://apps.apple.com/ru/app/%D1%88%D1%82%D1%80%D0%B0%D1%84%D1%8B-%D0%B3%D0%B8%D0%B1%D0%B4%D0%B4-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D1%80%D0%BA%D0%B0-%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D0%B0/id1475500802'
  active: number = 0;

  switchCard () {
    if (this.active < 2) {
      this.active += 1
    } else {
      this.active = 0
    }
    setTimeout(this.switchCard, 5000)
  }

  mounted () {
    this.switchCard()
  }
}
