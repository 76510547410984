

import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'OfficialSource'
})
export default class extends Vue {
  @Prop({ default: 'landing' }) classPage: 'static' | 'landing'
}
